import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link, withPrefix } from 'gatsby'
import { Grid, Row, Col } from 'react-flexbox-grid'
import styled from '@emotion/styled'
import { useIntl } from 'gatsby-plugin-intl'

import Layout from '../components/Layout'
import ResponsiveTitle from '../components/ResponsiveTitle'
import Card from '../components/Card'
import BaseButton from '../components/BaseButton'

const cardBackgroundColor = 'rgba(255,255,255,0.3)'

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 2rem 0;
`

export const CommissionsPageTemplate = ({ cards }) => {
  const { formatMessage } = useIntl()
  return (
    <Grid fluid>
      <Col xs={12}>
        <Row className="flex-justify-center">
          <ResponsiveTitle title={formatMessage({ id: 'commissions.title' })} />
        </Row>
        <Row>
          <Card
            backgroundColor={cardBackgroundColor}
            image={cards[0].card.image}
            text={formatMessage({ id: 'commissions.cards.1' })}
          />
          <Card
            backgroundColor={cardBackgroundColor}
            image={cards[1].card.image}
            text={formatMessage(
              { id: 'commissions.cards.2 | wallArtLink' },
              { wallArtLink: <Link to="/wall-art">{formatMessage({ id: 'nav.wall-art' })}</Link> }
            )}
          />
          <Card
            backgroundColor={cardBackgroundColor}
            image={cards[2].card.image}
            text={formatMessage(
              { id: 'commissions.cards.3 | externalLink' },
              {
                externalLink: (
                  <a target="_blank" rel="noopener noreferrer" href="https://nphoto.com/">
                    nphoto.com
                  </a>
                ),
              }
            )}
          />
          <Card
            backgroundColor={cardBackgroundColor}
            image={cards[3].card.image}
            text={formatMessage({ id: 'commissions.cards.4' })}
          />
        </Row>
        <Row>
          <ButtonContainer>
            <Link to={`${withPrefix('/')}contact`}>
              <BaseButton className="button is-large">
                {formatMessage({ id: 'commissions.get-in-touch' })}
              </BaseButton>
            </Link>
          </ButtonContainer>
        </Row>
      </Col>
    </Grid>
  )
}

CommissionsPageTemplate.propTypes = {
  cards: PropTypes.array,
}

const CommissionsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout>
      <CommissionsPageTemplate title={frontmatter.title} cards={frontmatter.cards} />
    </Layout>
  )
}

CommissionsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default CommissionsPage

export const pageQuery = graphql`
  query CommissionsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "commissions-page" } }) {
      html
      frontmatter {
        cards {
          card {
            image {
              childImageSharp {
                fluid(maxWidth: 526, quality: 92) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
