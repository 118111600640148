import React from 'react'
import T from 'prop-types'
import styled from '@emotion/styled'
import { Col } from 'react-flexbox-grid'
import Img from 'gatsby-image'
import { TABLET_MIN_BREAKPOINT } from '../constants/breakpoints'

const CardContainer = styled.div`
  display: flex;
  flex-flow: wrap-reverse;
  min-width: 100%;
  margin: 1.5rem 0;
  padding: 0;
  background-color: ${props => props.backgroundColor};
`

const CardContent = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0.75rem;

  @media screen and (${TABLET_MIN_BREAKPOINT}) {
    padding: 1.5rem;
  }
`

const StyledGatsbyImg = styled(Img)`
  border-radius: 6px 6px 0 0;

  @media screen and (${TABLET_MIN_BREAKPOINT}) {
    border-radius: 0 6px 6px 0;
  }
`

const Card = ({ image, text, backgroundColor }) => {
  return (
    <CardContainer
      className="card box has-box-shadow has-link-inline"
      backgroundColor={backgroundColor}
    >
      <Col xs={12} md={7}>
        <CardContent className="card-content">
          <p>{text}</p>
        </CardContent>
      </Col>
      <Col xs={12} md={5} className="no-padding-horizontal">
        <div className="card-image">
          <StyledGatsbyImg fluid={image.childImageSharp.fluid} />
        </div>
      </Col>
    </CardContainer>
  )
}

Card.propTypes = {
  image: T.object.isRequired,
  text: T.string.isRequired,
  backgroundColor: T.string,
}

export default Card
